import { request } from "./request"
import WC_GLOBALS from "../wp_globals/WOOCOMMERCE"
const { KEY, SECRET } = WC_GLOBALS.APIAUTH

class WPAPI {
  constructor() {
    this.route = "/wp-json/wc/v3"
    this.affiliate = "/wp-json/affwp/v1"
  }

  createReferral(code, amount, description) {
    const url =
      this.affiliate +
      `/referrals/?user_name=${code}&amount=${amount}&description=${description}`
    const config = {
      method: "POST",
      withCredentials: true,
      auth: {
        username: "a69949f23d65c2575d46f3a75710422b",
        password: "3b4ef2c5c50f056775453913f054624b",
      },
    }
    return request(url, config)
  }
  getAllPartners() {
    const url = "wp-json/partners/v1/get"
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: "a69949f23d65c2575d46f3a75710422b",
        password: "3b4ef2c5c50f056775453913f054624b",
      },
    }
    return request(url, config)
  }
  getAllAffiliates() {
    const url = this.affiliate + `/affiliates`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: "a69949f23d65c2575d46f3a75710422b",
        password: "3b4ef2c5c50f056775453913f054624b",
      },
    }
    return request(url, config)
  }

  getAllProducts() {
    const url = this.route + `/products?per_page=1000`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }

    return request(url, config)
  }

  getAllCoupons() {
    const url = this.route + `/coupons`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config)
  }

  getAllProductCategories() {
    const url = this.route + `/products/categories?per_page=100`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config)
  }

  postCreateOrder(customer, cart, shipping, coupon = {}) {
    const url = this.route + `/orders`
    const config = {
      method: "POST",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data: {
        billing: customer,
        shipping: customer,
        customer_note: customer.note,
        line_items: cart,
        shipping_lines: [shipping],
        ...coupon,
      },
    }

    return request(url, config)
  }

  changeOrder(id, data = { status: "completed" }) {
    const url = this.route + `/orders/${id}`
    const config = {
      method: "PUT",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data,
    }

    return request(url, config)
  }

  searchByPaymentId(paymentId) {
    const url = this.route + `/orders?search=${paymentId}`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }

    return request(url, config)
  }

  search(phrase = "") {
    const urlProducts = this.route + `/products/?search=${phrase}&per_page=100`
    const configProducts = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    const orders = () => request(urlProducts, configProducts)

    const urlPosts = `/wp-json/wp/v2/posts?search=${phrase}&per_page=100`
    const configPosts = {
      method: "GET",
    }
    const posts = () => request(urlPosts, configPosts)

    const urlPages = `/wp-json/wp/v2/pages?search=${phrase}&per_page=100`
    const configPages = {
      method: "GET",
    }
    const pages = () => request(urlPages, configPages)

    return Promise.all([orders(), posts(), pages()])
  }

  sendMail(email, content, query = {}) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/953/feedback"

    const data = new FormData()
    data.append("email", email)
    data.append("content", content)

    const config = {
      method: "POST",
      query: query,
      multipart: true,
      data,
    }
    return request(url, config)
  }

  signToNewsletter(email) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/1373/feedback"

    const data = new FormData()
    data.append("email", email)

    const config = {
      method: "POST",
      multipart: true,
      data,
    }
    return request(url, config)
  }

  catchCart(email, items) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/1157/feedback"

    const data = new FormData()
    data.append("email", email)
    data.append("items", items)

    const config = {
      method: "POST",
      multipart: true,
      data,
    }

    return request(url, config)
  }

  sendNewsletter(email, query = {}) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/420/feedback"

    const data = new FormData()
    data.append("email", email)

    const config = {
      method: "POST",
      query: query,
      multipart: true,
      data,
    }
    return request(url, config)
  }
  signByPopUp(email, query = {}) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/1589/feedback"

    const data = new FormData()
    data.append("email", email)

    const config = {
      method: "POST",
      query: query,
      multipart: true,
      data,
    }
    return request(url, config)
  }

  getDeliveryOptions() {
    // POST /wp-json/wc/v3/shipping/zones
    // POST /wp-json/wc/v3/shipping/zones/<id>/methods
    const url = "/wp-json/wc/v3/shipping/zones"
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config).then(data => {
      const [europe] = data.filter(el => el.name === "Europa")
      const url = `/wp-json/wc/v3/shipping/zones/${europe.id}/methods`
      const config = {
        method: "GET",
        withCredentials: true,
        auth: {
          username: KEY,
          password: SECRET,
        },
      }
      return request(url, config)
    })
  }

  // ###post comments section## //
  // retrieve all comments by post id
  retrievePostComments(postId) {
    const url = `/wp-json/wp/v2/comments/?post=${postId}`
    const config = {
      method: "GET",
    }
    return request(url, config)
  }

  // comment post by post id
  commentPost(postId, author, email, comment) {
    const url = "/wp-json/wp/v2/comments"
    const config = {
      method: "POST",
      data: {
        post: postId,
        author_name: author,
        author_email: email,
        content: comment,
      },
    }
    return request(url, config)
  }

  // ###reviews#### //
  createProductReview(id, form) {
    const url = "/wp-json/wc/v3/products/reviews"
    const config = {
      method: "POST",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
      data: {
        product_id: Number(id),
        ...form,
      },
    }
    return request(url, config)
  }

  retrieveProductReview(id) {
    const url = `/wp-json/wc/v3/products/reviews?product=${id}`
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config)
  }
}

const WPApi = new WPAPI()

export default WPApi
